import { errorResponse } from './response'

const templateAPi = $axios => {
  const http = $axios
  return {
    async create({ title, description, html, cost, variables }) {
      const data = {
        title,
        description,
        html,
        cost,
        variables
      }

      try {
        const req = await http.post('/templates', data)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async getAll({ params = {} }) {
      try {
        const req = await http.get('/templates', {
          params
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async get({ templateId, params }) {
      try {
        const req = await http.get(`/templates/${templateId}`, {
          params
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async update({ templateId, data }) {
      try {
        const req = await http.put(`/templates/${templateId}`, data)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async delete({ templateId }) {
      try {
        const req = await http.delete(`/templates/${templateId}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async accessAsLawyer({ templateId, data }) {
      try {
        const req = await http.post(`/templates/${templateId}/access`, data)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async uploadImage({ templateId, image }) {
      const data = new FormData()
      data.append('icon', image)

      try {
        const req = await http.post(
          `/templates/${templateId}/upload-icon`,
          data
        )
        return req
      } catch (error) {
        return errorResponse(error)
      }
    }
  }
}

export default templateAPi
