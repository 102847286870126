<template>
  <div>
    <tab-headers>
      Agreement Template
      <template #text> Manage Templates here </template>
    </tab-headers>
    <div class="flex items-center justify-end mb-3">
      <a-button type="primary" @click="$router.push('create-template')"
        >Create Template</a-button
      >
    </div>
    <div class="p-3">
      <div class="grid md:grid-cols-4 grid-cols-1 gap-5">
        <div
          v-for="template in templates"
          :key="template._id"
          :class="`${template._id} shadow-md rounded-md p-3 bg-white relative`"
        >
          <div
            class="absolute top-5 left-5 bg-white p-2 rounded-full shadow cursor-pointer"
          >
            <label
              v-if="uploadingImage !== template._id"
              :for="template._id"
              class="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                />
              </svg>
              <input
                type="file"
                :id="template._id"
                class="hidden"
                accept="image/*"
                @input="
                  $event => uploadImage(template._id, $event.target.files)
                "
              />
            </label>
            <div v-else>
              <a-spin size="small" />
            </div>
          </div>
          <div
            class="
              absolute
              top-5
              right-5
              font-medium
              text-xl text-black text-right
            "
          >
            <p
              class="text-sm text-white mb-0 bg-primary-dark px-3 py-1 rounded shadow"
            >
              Sold {{ template.purchaseCount }}
            </p>
          </div>
          <div class="" style="height: 250px">
            <img
              :src="template.icon || '../../assets/svg/agree_template.svg'"
              class="w-full block aspect-ratio object-cover h-full"
            />
          </div>
          <h2 class="text-lg font-semibold mb-0">{{ template.title }}</h2>
          <p class="text-xs text-gray-600 mb-3">
            {{ template.description }}
          </p>
          <h3 class="font-bold text-xl">₦{{ template.cost }}</h3>
          <div class="flex items-center justify-between">
            <a-button
              class="cursor-pointer text-base"
              type="edit"
              @click="
                $router.push(`create-template?templateId=${template._id}`)
              "
              >Edit</a-button
            >
            <a-button
              class="cursor-pointer text-base"
              type="danger"
              @click="deleteTemplate(template._id)"
              >Delete</a-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import templateAPi from '../../api/template'
import TabHeaders from '../../components/typography/TabHeaders.vue'
// import { FileJpgOutlined } from '@ant-design/icons-vue'

export default {
  name: 'AgreementTemplate',
  components: { TabHeaders },
  layout: 'admindashboard',
  data() {
    return {
      templates: [],
      uploadingImage: null
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  created() {
    this.getTemplates()
  },
  methods: {
    async getTemplates() {
      const req = await templateAPi(this.axios).getAll({ params: { limit: 0 } })
      this.templates = req.data.data
    },
    async uploadImage(templateId, files) {
      const file = files[0]

      this.uploadingImage = templateId

      const req = await templateAPi(this.axios).uploadImage({
        templateId,
        image: file
      })
      if (req.error) {
        this.$notification.error({
          message: 'Error',
          description: req.error
        })
        this.uploadingImage = null

        return
      }

      this.$notification.success({
        message: 'Success',
        description: req.message
      })
      this.getTemplates()
      this.uploadingImage = false
    },
    async dTemplate(id) {
      const req = await templateAPi(this.axios).delete({ templateId: id })
      if (req.error) {
        this.$notification.error({
          message: 'Error',
          description: req.error
        })
        return
      }
      this.getTemplates()
      this.$notification.success({
        message: 'Deleted',
        description: req.message
      })
    },
    deleteTemplate(id) {
      this.$confirm({
        title: 'Delete Template!',
        content: 'Please confirm you want to delete this template',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          this.dTemplate(id)
        },
        onCancel() {}
      })
    }
  }
}
</script>
